import { useDispatch, useSelector } from "react-redux";
import "./wallet-table.scss";
import { useEffect } from "react";
import { getWalletData } from "features/profile/profileSlices";
import { StringStore } from "StringStore";
const WalletTable = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.walletData);

  useEffect(() => {
    dispatch(getWalletData(currentProfile.code));
  }, [currentProfile.code]);

  const assetClassToColor = (assetClass: string) => {
    switch (assetClass) {
      case "Actions":
        return "action";
      case "Obligations":
        return "obligations";
      case "Monétaire":
        return "monetaire";
      case "Diversifié":
        return "diversifie";
      default:
        return "";
    }
  };
  const handleExcelButtonOnClick = () => {
    const excelData = data.map((row) => ({
      "Classe d'actifs": row.asset_class,
      "Catégorie": row.category_name,
      "Nom du fonds": row.security_name,
      "Société de gestion": row.issuer_company_name,
      "Code ISIN": row.id_security_ticker,  
      "Frais de gestion": `${(row.security_man_fee * 100).toFixed(1)}%`,
      "SRI": row.risk_class_name.split(" ")[1],
      "SFDR": row.sfdr_category.split(" ")[1],
      "Poids": `${(row.fund_holding_security_weight_in_ptf * 100).toFixed(1)}%`,
    }));
    
    const convertToCSV = (objArray) => {
      const headers = Object.keys(objArray[0]);
      
      let csvContent = headers.join(';') + '\n';
      
      objArray.forEach(obj => {
        const values = headers.map(header => {
          const value = obj[header] === null || obj[header] === undefined ? '' : obj[header].toString();
          return `"${value.replace(/"/g, '""')}"`;
        });
        csvContent += values.join(';') + '\n';
      });
      
      return csvContent;
    };
    const csvContent = convertToCSV(excelData);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    const name = currentProfile.name.replace(/ /g, '_');
    const date = new Date().toLocaleDateString('fr-FR');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}_portefeuille_${date}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const handleO2SButtonOnClick = () => {
    console.log("O2S button clicked");
  };
  return (
    <div className="wallet-table">
      <div className="wallet-table__title">Portefeuille</div>
      <div className="wallet-table__buttons">
        <div className="wallet-table__buttons__button" onClick={handleExcelButtonOnClick}>Portefeuille format Excel</div>
        {/* <div className="wallet-table__buttons__button">Portefeuille format O2S</div> */}
      </div>
      <div className="wallet-table__content">
        <table>
          <thead>
            <tr>
              <th>Classe d'actifs</th>
              <th>Catégorie</th>
              <th>Nom du fonds</th>
              <th>Société de gestion</th>
              <th>Code ISIN</th>
              <th>Frais de gestion</th>
              <th>SRI</th>
              <th>SFDR</th>
              <th>Poids</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              const weight = row.fund_holding_security_weight_in_ptf * 100;
              const formattedWeight = `${weight.toFixed(1)}%`;
              const formattedSRI = row.risk_class_name.split(" ")[1];
              const formattedSFDR = row.sfdr_category.split(" ")[1];
              return (
                <tr key={index}>
                  <td className={`${assetClassToColor(row.asset_class)} asset`}>
                    {row.asset_class}
                  </td>
                  <td>{row.category_name}</td>
                  <td className="bold">
                    <a
                      href={row.security_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.security_name}
                    </a>
                  </td>
                  <td>{row.issuer_company_name}</td>
                  <td>{row.id_security_ticker}</td>
                  <td>{`${(row.security_man_fee * 100).toFixed(1)}%`}</td>
                  <td>{formattedSRI}</td>
                  <td>{formattedSFDR}</td>
                  <td>{formattedWeight}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="wallet-table__disclaimer">
        {StringStore.profile.walletDisclaimer}
      </div>
    </div>
  );
};

export default WalletTable;
