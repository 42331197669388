import { createSlice } from "@reduxjs/toolkit";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";

import API, { ENDPOINTS } from "../../services/api";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../services/firebase";
import { AppRoutes } from "./types/app-routes";
import { updateAnalyticsLogins } from "./userSlices";
export enum LocalStorageKeys {
  userToken = "userToken",
}

const initialState = {
  user: null,
  isAuthenticated: false,
  isLoading: false,
  isLoadingAutoLogin: true,
  error: null,
  isEmailSent: false,
  isPasswordSent: false,
  isLoaded: false,
  eulaAccepted: true
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsLoadingAutoLogin: (state, action) => {
      state.isLoadingAutoLogin = action.payload;
    },
    setIsEmailSent: (state, action) => {
      state.isEmailSent = action.payload;
    },
    setIsPasswordSent: (state, action) => {
      state.isPasswordSent = action.payload;
    },
    setMailSent: (state, action) => {
      state.isEmailSent = action.payload;
    },
    setAuthSuccess: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isLoadingAutoLogin = false;
      state.isLoading = false;
    },
    setLogout: (state) => {
      state.user = null;
      state.isLoading = false;
      state.isAuthenticated = false;
      state.isLoadingAutoLogin = false;
      state.eulaAccepted = false;
    },
    setAuthFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.code;
    },
    setErrorNull: (state) => {
      state.error = null;
    },
    setEulaAccepted: (state) => {
      state.user = { ...state.user, eulaAccepted: true };
      state.eulaAccepted = true;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
    setEulaNotAccepted: (state) => {
      state.eulaAccepted = false;
      
    }
  },
});

export const authenticate =
  (email: string, password: string) => async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    dispatch(setErrorNull());
    try {
      const auth = getAuth();
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // TODO: Persist data in localStorage (token maybe)

      const userToken = await userCredentials.user.getIdToken();
      API.defaults.headers.common["token"] = userToken;
      const userRef = doc(db, "users", userCredentials.user.uid);
      const user = await getDoc(userRef);
      const userData = {
        uid: userCredentials.user.uid,
        email: userCredentials.user.email,
        token: userToken,
        displayName: userCredentials.user.displayName,
        ...user.data(),
      };
      dispatch(setAuthSuccess(userData));
      dispatch(updateAnalyticsLogins(userData.uid));
      dispatch(setEulaNotAccepted())
      saveTokenToLocalStorage(JSON.stringify(userData));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setAuthFailure(error));
      dispatch(setIsLoading(false));
    } finally {
      dispatch(setIsLoaded(true));
    }
  };
export const acceptEula = () => async (dispatch: Function) => {
  try {
    const user = getAuth().currentUser;
    const userRef = doc(db, "users", user.uid);
    const dbUser = await getDoc(userRef);
    const newDBUser = { ...dbUser.data(), eulaAccepted: true };
    await setDoc(userRef, newDBUser);
    dispatch(setEulaAccepted());
  } catch (error) {
    console.error(error);
  }
};
const saveTokenToLocalStorage = (token: any) => {
  localStorage.setItem(LocalStorageKeys.userToken, token);
};

export const logoutUser = (navigate) => async (dispatch: Function) => {
  try {
    const auth = getAuth();
    await signOut(auth);
    localStorage.removeItem(LocalStorageKeys.userToken);
    delete API.defaults.headers.common["authtoken"];
    dispatch(setLogout());
    navigate(AppRoutes.LOGIN);
  } catch (e) {
    console.error("Could not sign out from firebase");
  }
};

export const setMyPassword =
  (password: string) => async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const user = getAuth().currentUser;
      await updatePassword(user, password);
      dispatch(authenticate(user.email, password));
      await new Promise((r) => setTimeout(r, 2000));
      const userRef = doc(db, "users", user.uid);
      const dbUser = await getDoc(userRef);
      const newDBUser = { ...dbUser.data(), firstLogin: false };
      await setDoc(userRef, newDBUser);
      dispatch(setIsPasswordSent(true));
    } catch (error) {
      console.error(error);
      dispatch(setIsPasswordSent(false));
      dispatch(setAuthFailure(error));
      dispatch(setIsLoading(false));
    }
  };

export const resetPassword = (email: string) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));
  dispatch(setIsEmailSent(false));
  dispatch(setErrorNull());
  try {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
    dispatch(setIsEmailSent(true));
  } catch (error) {
    console.error(error);
    dispatch(setAuthFailure(error));
    dispatch(setIsLoading(false));
  }
};

export const checkAutoLogin2 = async (
  dispatch: Function,
  navigate,
  location
) => {
  dispatch(setIsLoading(true));
  try {
    const tokenString = localStorage.getItem(LocalStorageKeys.userToken);
    if (!tokenString) {
      dispatch(setLogout());
      return;
    }

    if (location.pathname !== "/set-my-password") {
      const userData = JSON.parse(tokenString);
      API.defaults.headers.common["token"] = userData.token;
      await API.post(`${ENDPOINTS.auth}/verify-token`);
      dispatch(setAuthSuccess(userData));
      navigate(location.pathname + location.search);
    } else {
      dispatch(setIsLoading(false));
      dispatch(setIsLoadingAutoLogin(false));
    }
  } catch (e) {
    console.error(e);
  }
};

export const checkAutoLogin = async (
  dispatch: Function,
  navigate,
  location
) => {
  dispatch(setIsLoading(true));
  try {
    const tokenString = localStorage.getItem(LocalStorageKeys.userToken);
    console.log(tokenString, 'auto login')
    if (!tokenString) {
      dispatch(setLogout());
      return;
    }

    if (location.pathname !== "/set-my-password") {
      const userData = JSON.parse(tokenString);
      API.defaults.headers.common["token"] = userData.token;
      await API.post(`${ENDPOINTS.auth}/verify-token`);
      const userRef = doc(db, "users", userData.uid);
      const user = await getDoc(userRef);
      const userDataAll = {
        ...userData,
        ...user.data(),
      };
      dispatch(setAuthSuccess(userDataAll));
      navigate(location.pathname + location.search);
    } else {
      dispatch(setIsLoading(false));
      dispatch(setIsLoadingAutoLogin(false));
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(setIsLoaded(true));
  }
};

export const {
  setIsLoading,
  setIsLoadingAutoLogin,
  setAuthSuccess,
  setAuthFailure,
  setErrorNull,
  setLogout,
  setIsEmailSent,
  setIsPasswordSent,
  setIsLoaded,
  setEulaAccepted,
  setEulaNotAccepted
} = authSlice.actions;

export default authSlice.reducer;
