// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desc-container-pdf {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
  margin-top: 0.5cm;
  justify-content: space-between;
  padding: 0.5cm 0.5cm 0 0.5cm;
}
.desc-container-pdf__explanation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 53%;
}
.desc-container-pdf__explanation__title {
  font-weight: 800;
  font-size: 0.49cm;
}
.desc-container-pdf__explanation__text {
  font-weight: 400;
  text-align: start;
  font-size: 0.35cm;
  font-family: Gotham, sans-serif;
}
.desc-container-pdf__ratio {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.desc-container-pdf__ratio__title {
  font-weight: 800;
  font-size: 0.49cm;
  margin-bottom: 2rem;
}
.desc-container-pdf__ratio__logo {
  height: 3.5cm;
}`, "",{"version":3,"sources":["webpack://./src/features/pdf/components/description-container-pdf/description-container-pdf.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,iBAAA;EACA,8BAAA;EACA,4BAAA;AACJ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,UAAA;AAER;AADQ;EACI,gBAAA;EACA,iBAAA;AAGZ;AADQ;EACI,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,+BAAA;AAGZ;AAAI;EACI,UAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAER;AADQ;EACI,gBAAA;EACA,iBAAA;EACA,mBAAA;AAGZ;AADQ;EACI,aAAA;AAGZ","sourcesContent":[".desc-container-pdf{\n    display: flex;\n    flex-direction: row;\n    height: fit-content;\n    width: 100%;\n    margin-top: 0.5cm;\n    justify-content: space-between;\n    padding: 0.5cm 0.5cm 0 0.5cm;\n    &__explanation{\n        display: flex;\n        flex-direction: column;\n        align-items: flex-start;\n        width: 53%;\n        &__title{\n            font-weight: 800;\n            font-size: 0.49cm;\n        }\n        &__text{\n            font-weight: 400;\n            text-align: start;\n            font-size: 0.35cm;\n            font-family: Gotham, sans-serif\n        }\n    }\n    &__ratio{\n        width: 45%;\n        display: flex;\n        flex-direction: column;\n        align-items: flex-start;\n        &__title{\n            font-weight: 800;\n            font-size: 0.49cm;\n            margin-bottom: 2rem;\n            }\n        &__logo{\n            height: 3.5cm;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
