// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desc-container {
  display: flex;
  flex-direction: row;
  height: fit-content;
}
.desc-container__explanation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 4rem 0 4rem;
  width: 60%;
}
.desc-container__explanation__title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 1rem;
}
.desc-container__explanation__text {
  font-size: 1rem;
  font-weight: 400;
  text-align: start;
  margin-bottom: 1;
}
.desc-container__ratio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 4rem 0 4rem;
}
.desc-container__ratio__title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 2rem;
}
@media (max-width: 2000px) {
  .desc-container__ratio__logo {
    height: 12.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/description-container/description-container.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,yBAAA;EACA,UAAA;AACR;AAAQ;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEZ;AAAQ;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAEZ;AACI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,yBAAA;AACR;AAAQ;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEZ;AACY;EADJ;IAEQ,eAAA;EAEd;AACF","sourcesContent":[".desc-container{\n    display: flex;\n    flex-direction: row;\n    height: fit-content;\n\n    &__explanation{\n        display: flex;\n        flex-direction: column;\n        align-items: flex-start;\n        padding: 3rem 4rem 0 4rem;\n        width: 60%;\n        &__title{\n            font-size: 1.25rem;\n            font-weight: 800;\n            margin-bottom: 1rem;\n        }\n        &__text{\n            font-size: 1rem;\n            font-weight: 400;\n            text-align: start;\n            margin-bottom: 1;\n        }\n    }\n    &__ratio{\n        display: flex;\n        flex-direction: column;\n        align-items: flex-start;\n        padding: 3rem 4rem 0 4rem;\n        &__title{\n            font-size: 1.25rem;\n            font-weight: 800;\n            margin-bottom: 2rem;\n        }\n        &__logo{\n            @media (max-width: 2000px) {\n                height: 12.5rem;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
