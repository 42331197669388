// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arbitration-table {
  height: fit-content;
  margin-bottom: 3rem;
  width: 100%;
}
.arbitration-table__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.arbitration-table__header__title {
  font-size: 1.25rem;
  font-weight: 800;
  text-align: start;
}
.arbitration-table__header__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EF936C;
  color: white;
  width: 16rem;
  height: 1.5rem;
  border-radius: 0.5rem;
  align-self: center;
  cursor: pointer;
}
.arbitration-table__content {
  height: 100%;
}
.arbitration-table__content a {
  color: black;
}
.arbitration-table__content .achat-vente-table {
  overflow-x: auto;
}
.arbitration-table__content table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.75rem;
}
.arbitration-table__content th,
.arbitration-table__content td {
  border: 0.0625rem solid #ddd;
  padding: 0.67rem;
  text-align: left;
  width: fit-content;
  text-wrap: nowrap;
}
.arbitration-table__content th:last-child,
.arbitration-table__content td:last-child {
  text-wrap: wrap;
}
.arbitration-table__content th {
  background-color: #4babb9;
  color: white;
}
.arbitration-table__content .bold {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/arbitration-table/arbitration-table.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,mBAAA;EACA,WAAA;AACD;AAAC;EACC,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAEF;AADE;EACI,kBAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AADE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;EACA,eAAA;AAGJ;AAAA;EACI,YAAA;AAEJ;AADI;EACE,YAAA;AAGN;AADI;EACI,gBAAA;AAGR;AAAM;EACE,yBAAA;EACA,WAAA;EACA,kBAAA;AAER;AACM;;EAEE,4BAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AACR;AACQ;;EACE,eAAA;AAEV;AAEM;EACE,yBAAA;EACA,YAAA;AAAR;AAGM;EACE,iBAAA;AADR","sourcesContent":[".arbitration-table{\n height: fit-content;\n margin-bottom: 3rem;\n width: 100%;\n &__header{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n  &__title{\n      font-size: 1.25rem;\n      font-weight: 800;\n      text-align: start;\n  }\n  &__button{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #EF936C;\n    color: white;\n    width: 16rem;\n    height: 1.5rem;\n    border-radius: 0.5rem;\n    align-self: center;\n    cursor: pointer;\n  }\n}\n&__content{\n    height: 100%;\n    a{\n      color: black;\n    }\n    .achat-vente-table {\n        overflow-x: auto;\n      }\n      \n      table {\n        border-collapse: collapse;\n        width: 100%;\n        font-size: 0.75rem;\n      }\n      \n      th,\n      td {\n        border: 0.0625rem solid #ddd;\n        padding: 0.67rem;\n        text-align: left;\n        width: fit-content;\n        text-wrap: nowrap;\n      \n        &:last-child {\n          text-wrap: wrap;\n        }\n      }\n      \n      th {\n        background-color: #4babb9;\n        color: white;\n      }\n      \n      .bold {\n        font-weight: bold;\n      }\n      \n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
