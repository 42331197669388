import JsPDF from "jspdf";
import { useState } from "react";
import { cssSelectorToDataURL } from "utils/cssSelectorToDataURL";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export const useGeneratePdf = () => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const handleDownloadPdf = async () => {
    setIsGenerating(true);
    try {
      const pdf = new jsPDF("portrait", "cm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Configuration for html2canvas to reduce quality/size
      const canvasOptions = { 
        scale: 1.5, // Reduced from 2 to decrease resolution
        useCORS: true,
        logging: false,
        imageTimeout: 0,
        // Set explicit white background to prevent black background
        backgroundColor: "#FFFFFF"
      };

      const pageSelectors = [
        ".pdf__pages__one",
        ".pdf__pages__two",
        ".pdf__pages__three",
        ".pdf__pages__four",
        ".pdf__pages__five"
      ];

      for (let i = 0; i < pageSelectors.length; i++) {
        const page = document.querySelector(pageSelectors[i]) as HTMLElement;
        
        if (page) {
          if (i > 0) {
            pdf.addPage();
          }
          
          const canvas = await html2canvas(page, canvasOptions);
          // Reduce image quality to make PDF lighter
          const imageData = canvas.toDataURL("image/jpeg", 0.7); // Using JPEG with 70% quality
          pdf.addImage(imageData, "JPEG", 0, 0, pdfWidth, pdfHeight);
        } else {
          console.error(`Element ${pageSelectors[i]} not found`);
        }
      }

      const dateTime = new Date().toLocaleDateString().replace(/\//g, "-");
      pdf.save(`ElkanoReport_${dateTime}.pdf`);
    } catch (error) {
      console.error(error);
    }
    setIsGenerating(false);
  };
  return { handleDownloadPdf, isGenerating };
};
