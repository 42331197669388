import { useCallback, useEffect, useMemo } from "react";
import "./wallet-commentary-pdf.scss";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getWalletCommentary } from "features/profile/profileSlices";
import { textFormatter } from "utils/fileFormatter";

const WalletCommentaryPdf = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.walletCommentary);

  useEffect(() => {
    dispatch(getWalletCommentary(currentProfile.code));
  }, [currentProfile.code]);

  const formattedData = useMemo(() => {
    return textFormatter(data);
  }, [data]);

  return (
    <div className="wallet-commentary-pdf">
      <div className="wallet-commentary-pdf__title">Structure du portefeuille</div>
      <div className="wallet-commentary-pdf__content" dangerouslySetInnerHTML={{ __html: formattedData }}></div>
    </div>
  );
};

export default WalletCommentaryPdf;
