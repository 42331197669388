import "./profile.scss";
import DistributionChart from "../distribution-chart/distribution-chart";
import PerformanceChart from "../performance-chart/performance-chart";
import KeysContainer from "../keys-container/keys-container";
import CommentaryContainer from "../commentary-container/commentary-container";
import DescriptionContainer from "../description-container/description-container";
import TopBar from "../top-bar/top-bar";
import InfoSideBar from "../info-side-bar/info-side-bar";
import EvolutionChart from "../evolution-chart/evolution-chart";
import ArbitrationTable from "../arbitration-table/arbitration-table";
import WalletTable from "../wallet-table/wallet-table";
import { StringStore } from "StringStore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGeneratePdf } from "../../../pdf/hooks/useGeneratePdf";
import { setCurrentProfile } from "features/profile/profileSlices";
import Eula from "../../eula/eula";
import MonthlyPerformance from "../monthly-performance/monthly-performance";
import AggregatedPerformance from "../aggregated-performance/aggregated-performance";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "features/authentication/types/app-routes";
import { setEulaAccepted } from "features/authentication/authSlices";
import { getProfiles } from "features/profile/profileSlices";
import { migrateUsers } from "features/authentication/userSlices";
import WalletCommentary from "../wallet-commentary/wallet-commentary";
const Profile = () => {
  const dispatch: Function = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const hasNoProfile = !user || !user.profiles || user?.profiles?.length === 0;
  const data = useSelector((state: any) => state.profile.performanceChartData);
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const profiles = useSelector((state: any) => state.profile.profiles);
  const eulaAccepted = useSelector((state: any) => state.auth.eulaAccepted);
  const [isEulaVisible, setIsEulaVisible] = useState(!eulaAccepted);

  useEffect(() => {
    dispatch(getProfiles());
  }, []);

  useEffect(() => {
    if (profiles.length === 0 || !user) return;
    dispatch(setCurrentProfile(profiles[user.profiles[0]]));
  }, [user, profiles]);

  useEffect(() => {
    document.body.style.overflow = eulaAccepted ? "auto" : "hidden";
  }, [eulaAccepted]);

  const lastUpdateDate = data.maxDate
  const data_at = lastUpdateDate ? `${StringStore.profile.explanationButton} ${lastUpdateDate}` : `Données au N/A`
  const handleAcceptEula = (accepted: boolean) => {
    dispatch(setEulaAccepted());
    setIsEulaVisible(!accepted);
  };

  const handleGeneratePdfOnClick = () => {
    window.open(
      AppRoutes.PDF + "?profile=" + currentProfile.code,
      "_blank"
    )
  }
  return (
    <div className="profile">
      <TopBar adminAccess={user?.admin || false} adminView={false} />
      {hasNoProfile || !currentProfile?.code ? (
        <div className="profile__no-profile">
          {StringStore.profile.noProfile}
        </div>
      ) : (
        <>
          <DescriptionContainer />
          <div className="profile__content">
            <div className="profile__content__data">
              <div className="profile__content__data__upper-generation-button-container">
                <div className="profile__content__data__upper-generation-button-container__data-date">{data_at}</div>
                <div
                  className="profile__content__data__upper-generation-button-container__button"
                  onClick={() =>handleGeneratePdfOnClick()}
                >
                  {StringStore.profile.generationButton}
                </div>
              </div>
              <div className="profile__content__data__container">
                <CommentaryContainer />
                <div className="profile__content__data__container__separator"></div>
                <KeysContainer />
              </div>
              <WalletTable />
              <WalletCommentary />
              <div className="profile__content__data__assets-charts">
                <DistributionChart />
                <EvolutionChart />
              </div>
              <PerformanceChart />
              <MonthlyPerformance />
              <AggregatedPerformance />
              
              <ArbitrationTable />
              <div className="profile__content__data__generation-button-container">
                <div
                  className="profile__content__data__generation-button-container__button"
                  onClick={() =>handleGeneratePdfOnClick()}
                >
                  {StringStore.profile.generationButton}
                </div>
              </div>
              <div className="profile__content__data__disclaimer">
                {StringStore.profile.globalDisclaimer}
              </div>
            </div>
            <InfoSideBar />
          </div>
        </>
      )}
      {isEulaVisible && <Eula setEulaAccepted={handleAcceptEula} />}
    </div>
  );
};

export default Profile;
