import { createSlice } from "@reduxjs/toolkit";
import API, { ENDPOINTS } from "../../services/api";
import { getAuth } from "firebase/auth";

export enum LocalStorageKeys {
  userToken = "userToken",
}

const initialState = {
  currentProfile: {
    code: null,
    name: null,
    type: null,
  },
  profiles: [],
  description: null,
  commentary: null,
  keys: {
    perf: null,
    risk: null,
    volatility: null,
    maxLoss: null,
  },
  distributionChartData: { labels: [], series: [] },
  evolutionChartData: { labels: [], series: [] },
  performanceChartData: { labels: [], series: [] },
  arbitraryData: [],
  arbitratyHistoryData: [],
  walletData: [],
  monthlyChronicleUrl: null,
  monthlyPerformance: {},
  aggregatedPerformance: {},
  walletCommentary: null,
};

const getTokenExpiration = (token) => {
  try {
      // Get the payload part of the token (second part)
      const payload = token.split('.')[1];
      
      // Base64Url decode the payload
      const decoded = JSON.parse(atob(payload.replace(/-/g, '+').replace(/_/g, '/')));
      
      // Check if exp claim exists
      if (!decoded.exp) {
          throw new Error('Token has no expiration claim');
      }
      
      // Get current time in seconds
      
      // Compare expiration time with current time
      return decoded.exp;
  } catch (error) {
      throw new Error(`Invalid token`);
  }
}


const getAuthToken = async () => {
  try {
    const userDataString = localStorage.getItem(LocalStorageKeys.userToken);
    const userData = JSON.parse(userDataString);
    
    // Check if token is expired or will expire soon (within 5 minutes)
    const currentTime = Date.now();
    const fiveMinutes = 5 * 60 * 1000;
    const tokenExpiration = getTokenExpiration(userData.token);
    
    if (tokenExpiration - currentTime <= fiveMinutes) {
      // Token will expire soon, refresh it using Firebase
      const auth = getAuth();
      const currentUser = auth.currentUser;
      
      if (currentUser) {
        const newToken = await currentUser.getIdToken(true); // Force refresh
        // Update token in the existing userData object
        userData.token = newToken;
        localStorage.setItem(LocalStorageKeys.userToken, JSON.stringify(userData));
        return newToken;
      }
    }
    
    return userData.token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setCurrentProfile: (state, action) => {
      state.currentProfile = action.payload;
    },
    setProfiles: (state, action) => {
      state.profiles = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setCommentary: (state, action) => {
      state.commentary = action.payload;
    },
    setKeys: (state, action) => {
      state.keys = action.payload;
    },
    setDistributionChartData: (state, action) => {
      state.distributionChartData = action.payload;
    },
    setEvolutionChartData: (state, action) => {
      state.evolutionChartData = action.payload;
    },
    setPerformanceChartData: (state, action) => {
      state.performanceChartData = action.payload;
    },
    setArbitraryData: (state, action) => {
      state.arbitraryData = action.payload;
    },
    setArbitraryHistoryData: (state, action) => {
      state.arbitratyHistoryData = action.payload;
    },
    setWalletData: (state, action) => {
      state.walletData = action.payload;
    },
    setMonthlyChronicleUrl: (state, action) => {
      state.monthlyChronicleUrl = action.payload;
    },
    setMonthlyPerformance: (state, action) => {
      state.monthlyPerformance = action.payload;
    },
    setAggregatedPerformance: (state, action) => {
      state.aggregatedPerformance = action.payload;
    },
    setWalletCommentary: (state, action) => {
      state.walletCommentary = action.payload;
    },
  },
});

export const getProfiles = () => async (dispatch: Function) => {
  try {
    const token = await getAuthToken();
    API.defaults.headers.common["token"] = token;
    const res = await API.get(`${ENDPOINTS.product}/profiles`);
    if (res.status === 200) {
      const data = res.data.reduce((acc, obj) => {
        acc[obj.fund_code] = {
          code: obj.fund_code,
          name: obj.fund_name,
          type: obj.fund_profile_type,
        };

        return acc;
      }, {});
      dispatch(setProfiles(data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getCommentary = (profileCode) => async (dispatch: Function) => {
  try {
    const token = await getAuthToken();
    API.defaults.headers.common["token"] = token;
    const res = await API.get(
      `${ENDPOINTS.product}/contexte?code_fund_asset=${profileCode}`
    );
    if (res.status === 200) {
      const data = res.data[0];
      dispatch(setCommentary(data.contexte_text));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getDescripton = (profileCode) => async (dispatch: Function) => {
  try {
    const token = await getAuthToken();
    API.defaults.headers.common["token"] = token;
    const res = await API.get(
      `${ENDPOINTS.product}/descriptifs?code_fund_asset=${profileCode}`
    );
    if (res.status === 200) {
      const data = res.data[0];
      dispatch(setDescription(data.profile_full_des));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getProfileKeys = (profileCode) => async (dispatch: Function) => {
  try {
    const token = await getAuthToken();
    API.defaults.headers.common["token"] = token;
    const res = await API.get(
      `${ENDPOINTS.product}/performance_since_first_datapoint?code_fund_asset=${profileCode}`
    );
    if (res.status === 200) {
      const data = res.data;
      const keys = {
        perf: data.perf_since,
        risk: data.risk,
        volatility: data.volatilite,
        maxLoss: data.max_loss,
        since: data.since,
      };
      dispatch(setKeys(keys));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getDistributionChartSeries =
  (profileCode) => async (dispatch: Function) => {
    try {
      const token = await getAuthToken();
      API.defaults.headers.common["token"] = token;
      const res = await API.get(
        `${ENDPOINTS.product}/repartition_classes_actifs_latest?code_fund_asset=${profileCode}`
      );
      if (res.status === 200) {
        const series = Object.values(res.data[0].data).map(
          (value) => Number(value) * 100
        );
        const labels = Object.keys(res.data[0].data);
        dispatch(setDistributionChartData({ labels, series }));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getEvolutionChartSeries =
  (profileCode) => async (dispatch: Function) => {
    try {
      const token = await getAuthToken();
      API.defaults.headers.common["token"] = token;
      const res = await API.get(
        `${ENDPOINTS.product}/repartition_classes_actifs_all?code_fund_asset=${profileCode}`
      );
      if (res.status === 200) {
        const transformedData = res.data.reduce(
          (acc, obj) => {
            const actions = obj.data.Actions;
            const obligations = obj.data.Obligations;
            const monetaire = obj.data.Monetaire;

            acc.actionsSerie.push(actions);
            acc.obligationsSerie.push(obligations);
            acc.monetaireSerie.push(monetaire);
            acc.labels.push(obj.asset_date_expo);

            return acc;
          },
          {
            actionsSerie: [],
            obligationsSerie: [],
            monetaireSerie: [],
            labels: [],
          }
        );

        const series = [
          {
            name: "Actions",
            data: transformedData.actionsSerie,
          },
          {
            name: "Obligations",
            data: transformedData.obligationsSerie,
          },
          {
            name: "Monétaire",
            data: transformedData.monetaireSerie,
          },
        ];
        dispatch(
          setEvolutionChartData({ labels: transformedData.labels, series })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  export const getWalletCommentary = (profileCode) => async (dispatch: Function) => {
    try {
      const token = await getAuthToken();
      API.defaults.headers.common["token"] = token;
      const res = await API.get(
        `${ENDPOINTS.product}/structure_portefeuille?code_fund_asset=${profileCode}`
      );
      if (res.status === 200) {
        dispatch(setWalletCommentary(res.data));
      }
    } catch (error) {
      console.error(error);
    }
  };  

export const getPerformanceChartSeries =
  (profileCode) => async (dispatch: Function) => {
    try {
      const token = await getAuthToken();
      API.defaults.headers.common["token"] = token;
      const res = await API.get(
        `${ENDPOINTS.product}/performance?code_fund_asset=${profileCode}`
      );
      if (res.status === 200) {
        const maxDate = res.data[res.data.length - 1].valuation_date
        const data = res.data   
        const labels = data.map((obj) => obj.valuation_date);

        const transformedData = data.reduce(
          (acc, obj) => {
            const fundValue =
              Math.round((obj.fund_NAV + Number.EPSILON) * 100) / 100;
            const categoryValue =
              Math.round((obj.categ_value + Number.EPSILON) * 100) / 100;

            acc.fundSerie.push(fundValue);
            acc.categorySerie.push(categoryValue);

            return acc;
          },
          { fundSerie: [], categorySerie: [] }
        );
        const series =
          data.length === 0
            ? []
            : [
                {
                  name: data[0].fund_name,
                  data: transformedData.fundSerie,
                },
                // {
                //   name: data[0].categ_profile_full_extern_name,
                //   data: transformedData.categorySerie,
                // },
              ];
        dispatch(setPerformanceChartData({ labels, series, maxDate }));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getArbitraryData = (profileCode) => async (dispatch: Function) => {
  try {
    const token = await getAuthToken();
    API.defaults.headers.common["token"] = token;
    const res = await API.get(
      `${ENDPOINTS.product}/arbitrages_latest?code_fund_asset=${profileCode}`
    );
    if (res.status === 200) {
      const data = res.data;
      dispatch(setArbitraryData(data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getMonthlyPerformance =
  (profileCode) => async (dispatch: Function) => {
    try {
      const token = await getAuthToken();
      API.defaults.headers.common["token"] = token;
      const res = await API.get(
        `${ENDPOINTS.product}/performance_mensuelle?code_fund_asset=${profileCode}`
      );
      if (res.status === 200) {
        const data = res.data;
        dispatch(setMonthlyPerformance(data));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getAggregatedPerformance =
  (profileCode) => async (dispatch: Function) => {
    try {
      const token = await getAuthToken();
      API.defaults.headers.common["token"] = token;
      const res = await API.get(
        `${ENDPOINTS.product}/performance_since_first_datapoint?code_fund_asset=${profileCode}`
      );
      if (res.status === 200) {
        const data = res.data;
        dispatch(setAggregatedPerformance(data));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getArbitraryHistoryData =
  (profileCode) => async (dispatch: Function) => {
    try {
      const token = await getAuthToken();
      API.defaults.headers.common["token"] = token;
      const res = await API.get(
        `${ENDPOINTS.product}/arbitrages?code_fund_asset=${profileCode}`
      );
      if (res.status === 200) {
        const data = res.data;
        dispatch(setArbitraryHistoryData(data));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getWalletData = (profileCode) => async (dispatch: Function) => {
  try {
    const token = await getAuthToken();
    API.defaults.headers.common["token"] = token;
    const res = await API.get(
      `${ENDPOINTS.product}/portefeuille?code_fund_asset=${profileCode}`
    );
    if (res.status === 200) {
      const data = res.data;
      dispatch(setWalletData(data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getMonthlyChronicleUrl = () => async (dispatch: Function) => {
  try {
    const token = await getAuthToken();
    API.defaults.headers.common["token"] = token;
    const res = await API.get(`${ENDPOINTS.product}/url_chronique`);
    if (res.status === 200) {
      const data = res.data.URL_chronique;
      dispatch(setMonthlyChronicleUrl(data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const {
  setCurrentProfile,
  setProfiles,
  setDescription,
  setCommentary,
  setDistributionChartData,
  setEvolutionChartData,
  setPerformanceChartData,
  setKeys,
  setArbitraryData,
  setMonthlyPerformance,
  setArbitraryHistoryData,
  setWalletData,
  setMonthlyChronicleUrl,
  setAggregatedPerformance,
  setWalletCommentary,
} = profileSlice.actions;

export default profileSlice.reducer;
