import React, { useEffect } from "react";
import "./evolution-chart.scss";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getEvolutionChartSeries } from "features/profile/profileSlices";
import { ClipLoader } from "react-spinners";
const getOptions = (labels: []) => {
  const options: ApexOptions = {
    chart: {
      type: "area",
      stacked: true,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 1440,
        options: {
          chart: {
            width: 400,
            height: 360,
          },
        },
      },
      {
        breakpoint: 1451,
        options: {
          chart: {
            width: 500,
            height: 360,
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 600,
          },
        },
      },
    ],
    legend: {
      onItemHover: {
        highlightDataSeries: false,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      fontSize: "12px",
      position: "bottom",
      labels: {
        colors: ["#4babb9", "#ef936c", "#031280", "#7f7f7f"],
      },
      markers: {
        radius: 1,
        height: 8,
        width: 8,
      },
    },
    colors: ["#4babb9", "#ef936c", "#031280", "#7f7f7f"],
    fill: {
      type: "solid",
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: labels,
      tickAmount: 4,
    },
    yaxis: {
      max: 1,
      tickAmount: 5,
      labels: {
        formatter: function (value) {
          return Math.ceil(value * 100) + "%";
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };
  return options;
};

const EvolutionChart = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.evolutionChartData);

  useEffect(() => {
    dispatch(getEvolutionChartSeries(currentProfile.code));
  }, [currentProfile.code]);

  return (
    <div className="evolution">
      <div className="evolution__title">
        Evolution de l'allocation du portefeuille
      </div>
      {!!data.series?.length ? (
        <ReactApexChart
          options={getOptions(data.labels)}
          series={data.series}
          type="area"
          width={600}
          height={350}
        />
      ) : (
        <div className="evolution__content">
          <ClipLoader color={"#EF936C"} loading={true} size={100} />
        </div>
      )}
    </div>
  );
};

export default EvolutionChart;
