import { useDispatch, useSelector } from "react-redux";
import "./monthly-performance-pdf.scss";
import { useEffect, useMemo, useState } from "react";
import { getMonthlyPerformance } from "features/profile/profileSlices";

const MonthlyPerformancePdf = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.monthlyPerformance);
  const [currentYearData, setCurrentYearData] = useState([]);
  const [lastYearData, setLastYearData] = useState([]);
  const [lastTwoYearsData, setLastTwoYearsData] = useState([]);
  const [currentYearQuantalysData, setCurrentYearQuantalysData] = useState([]);
  const [lastYearQuantalysData, setLastYearQuantalysData] = useState([]);
  const [lastTwoYearsQuantalysData, setLastTwoYearsQuantalysData] = useState([]);

  const rawLastYearData = useMemo(()=>data?.dataframe?.filter(obj => obj.year === new Date().getFullYear() - 1),[data?.dataframe]);
  const rawCurrentYearData = useMemo(()=> data?.dataframe?.filter(obj => obj.year === new Date().getFullYear()),[data?.dataframe]);
  const rawLastTwoYearsData = useMemo(()=> data?.dataframe?.filter(obj => obj.year === new Date().getFullYear() - 2),[data?.dataframe]);
  const rawCurrentYearQuantalysData = useMemo(()=> data?.dataframe_quantalys?.filter(obj => obj.year === new Date().getFullYear()),[data?.dataframe_quantalys]);
  const rawLastYearQuantalysData = useMemo(()=> data?.dataframe_quantalys?.filter(obj => obj.year === new Date().getFullYear() - 1),[data?.dataframe_quantalys]);
  const rawLastTwoYearsQuantalysData = useMemo(()=> data?.dataframe_quantalys?.filter(obj => obj.year === new Date().getFullYear() - 2),[data?.dataframe_quantalys]);

  useEffect(() => {
    dispatch(getMonthlyPerformance(currentProfile.code));
  }, [currentProfile.code]);
  const headers = [
    "",
    "",
    "YTD",
    "Jan.",
    "Fev.",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juil.",
    "Aout",
    "Sept.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];
  const monthToNumber = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  const formatRawData = (rawData) => {
    const formattedData = new Array(13).fill("-");
    rawData?.forEach((row) => {
      const monthNumber = monthToNumber[row.month_name];
      formattedData[monthNumber] = row.variation
        ? `${Number(row.variation * 100).toFixed(2)}%`
        : "-";
    });
    return formattedData;
  }

  const appendYtdValue = (formattedData, ytdValue) => {
    formattedData[0] = ytdValue
      ? `${Number(ytdValue * 100).toFixed(2)}%`
      : "-";
  }
  useEffect(() => {
    console.log(data)
    const formattedLastYearData = formatRawData(rawLastYearData);
    const formattedCurrentYearData = formatRawData(rawCurrentYearData);
    const formattedLastTwoYearsData = formatRawData(rawLastTwoYearsData);
    const formattedCurrentYearQuantalysData = formatRawData(rawCurrentYearQuantalysData);
    const formattedLastYearQuantalysData = formatRawData(rawLastYearQuantalysData);
    const formattedLastTwoYearsQuantalysData = formatRawData(rawLastTwoYearsQuantalysData);
    setCurrentYearData(formattedCurrentYearData);
    setLastYearData(formattedLastYearData);
    setLastTwoYearsData(formattedLastTwoYearsData);

    setCurrentYearQuantalysData(formattedCurrentYearQuantalysData);
    setLastYearQuantalysData(formattedLastYearQuantalysData);
    setLastTwoYearsQuantalysData(formattedLastTwoYearsQuantalysData);

    appendYtdValue(formattedCurrentYearData, data.ytd_value);
    appendYtdValue(formattedLastYearData, data.ytd_value_last_year);
    appendYtdValue(formattedLastTwoYearsData, data.ytd_value_two_years_ago);
    
    appendYtdValue(formattedCurrentYearQuantalysData, data.ytd_value_quantalys);
    appendYtdValue(formattedLastYearQuantalysData, data.ytd_value_last_year_quantalys);
    appendYtdValue(formattedLastTwoYearsQuantalysData, data.ytd_value_two_years_ago_quantalys);
    
  }, [data, currentProfile.code]);

  const formattedHeaders = useMemo(() => {
    const newHeaders = headers.map((header, index) => {
      return <th key={index}>{header}</th>;
    });
    return newHeaders;
  }, []);
  const hasLastTwoYearsData = useMemo(()=> lastTwoYearsData.some((value) => value !== "-"),[lastTwoYearsData]);
  const hasLastYearData = useMemo(()=> lastYearData.some((value) => value !== "-"),[lastYearData]);
  const hasCurrentYearData = useMemo(()=> currentYearData.some((value) => value !== "-"),[currentYearData]);
  const currentDate = new Date().getFullYear()
  return (
    <div className="monthly-performance-pdf">
      <div className="monthly-performance-pdf__header">
        <div className="monthly-performance-pdf__header__title">
          Performance mensuelle
        </div>
      </div>
      <div className="monthly-performance-pdf__content">
        <table>
          <thead>
            <tr>{formattedHeaders}</tr>
          </thead>
          <tbody>
            { hasLastTwoYearsData && <>
            <tr key={"profile-values-last-two-years"}>
            <td rowSpan={3}>{currentProfile.name}</td>
            <td className="year" rowSpan={1}>{currentDate -2}</td>
              {lastTwoYearsData.map((value, index) => {
                return <td key={index}>{value}</td>;
              })}
            </tr>
            {/* <tr key={"quantalys-values-last-two-years"}>
            <td>{`Catégorie Quantalys`}</td>
              {lastTwoYearsQuantalysData.map((value, index) => {
                return <td key={index}>{value}</td>;
              })}
            </tr> */}
            </>}
            { hasLastYearData && <>
            <tr key={"profile-values-last-year"}>
            <td className="year" rowSpan={1}>{currentDate-1}</td>
              {lastYearData.map((value, index) => { 
                return <td key={index}>{value}</td>;
              })}
            </tr>
            {/* <tr key={"quantalys-values-last-year"}>
            <td>{`Catégorie Quantalys`}</td>
              {lastYearQuantalysData.map((value, index) => {
                return <td key={index}>{value}</td>;
              })}
            </tr> */}
            </>}
            { hasCurrentYearData && <>
            <tr key={"profile-values-current-year"}>
            <td className="year" rowSpan={1}>{currentDate}</td>
              {currentYearData.map((value, index) => {
                return <td key={index}>{value}</td>;
              })}
            </tr>
            {/* <tr key={"quantalys-values-current-year"}>
              <td>{`Catégorie Quantalys`}</td>
              {currentYearQuantalysData.map((value, index) => {
                return <td key={index}>{value}</td>;
              })}
            </tr> */}
            </>}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MonthlyPerformancePdf;
