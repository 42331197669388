import "./elkano-pdf.scss";
import DistributionChartPdf from "../distribution-chart-pdf/distribution-chart-pdf";
import PerformanceChartPdf from "../performance-chart-pdf/performance-chart-pdf";
import KeysContainerPdf from "../keys-container-pdf/keys-container-pdf";
import TopBar from "../top-bar/top-bar";
import EvolutionChartPdf from "../evolution-chart-pdf/evolution-chart-pdf";
import ArbitrationTablePdf from "../arbitration-table-pdf/arbitration-table-pdf";
import WalletTablePdf from "../wallet-table-pdf/wallet-table-pdf";
import { StringStore } from "StringStore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGeneratePdf } from "../../hooks/useGeneratePdf";
import MonthlyPerformancePdf from "../monthly-performance-pdf/monthly-performance-pdf";
import AggregatedPerformancePdf from "../aggregated-performance-pdf/aggregated-performance-pdf";
import DescriptionContainerPdf from "../description-container-pdf/description-container-pdf";
import FullCommentaryPdf from "../full-commentary/full-commentary-pdf";
import Signature from "../signature/signature";
import HeaderPdf from "../header-pdf/header-pdf";
import { useElkanoAnalytics } from "features/profile/hooks/useElkanoAnalytics";
import { getProfiles, setCurrentProfile } from "features/profile/profileSlices";
import WalletCommentaryPdf from "../wallet-commentary-pdf/wallet-commentary-pdf";
const ElkanoPdf = () => {
  const dispatch: Function = useDispatch();
  const { addGeneratePdfEvent } = useElkanoAnalytics();
  const { handleDownloadPdf } = useGeneratePdf();
  const user = useSelector((state: any) => state.auth.user);
  const hasNoProfile = !user || !user.profiles || user?.profiles?.length === 0;
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const profiles = useSelector((state: any) => state.profile.profiles);
  const eulaAccepted = useSelector(
    (state: any) => state.auth.user?.eulaAccepted
  );
  useEffect(() => {
    dispatch(getProfiles());
  }, []);
  useEffect(() => {
    if (profiles.length === 0 || !user) return;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const profileName = urlParams.get("profile");
    dispatch(setCurrentProfile(profiles[profileName]));
  }, [user, profiles]);

  useEffect(() => {
    if (!eulaAccepted) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [eulaAccepted]);

  return (
    <div className="pdf">
      <TopBar
        adminAccess={user?.admin || false}
        adminView={false}
        profileAccess={true}
      />
      {hasNoProfile || !currentProfile?.code ? (
        <div className="pdf__no-profile">{StringStore.profile.noProfile}</div>
      ) : (
        <div className="pdf__pages">
          <div
            className="pdf__button"
            onClick={() => {
              handleDownloadPdf();
              addGeneratePdfEvent(currentProfile.name);
            }}
          >
            {StringStore.profile.generationButton}
          </div>
          <div className="pdf__pages__one">
            <HeaderPdf />
            <DescriptionContainerPdf />
            <FullCommentaryPdf isVisible={true} setVisible={() => {}} />
          </div>
          <div className="pdf__pages__two">
            <HeaderPdf />
            <KeysContainerPdf />
            <WalletTablePdf />
            {/* <WalletCommentaryPdf /> */}
            <div className="pdf__pages__two__row">
              <DistributionChartPdf />
              <EvolutionChartPdf />
            </div>
            
          </div>
          <div className="pdf__pages__three">
            <HeaderPdf />
            <WalletCommentaryPdf />
            <PerformanceChartPdf />
            <MonthlyPerformancePdf />
            <AggregatedPerformancePdf />
            
          </div>
          <div className="pdf__pages__four">
            <HeaderPdf />
            <ArbitrationTablePdf />
          </div>
          <div className="pdf__pages__five">
            <HeaderPdf />
            <Signature />
            <div className="pdf__pages__five__disclaimer">
              {StringStore.profile.pdfGlobalDisclaimer}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ElkanoPdf;
