import { useDispatch, useSelector } from "react-redux";
import "./aggregated-performance-pdf.scss";
import { useEffect } from "react";
import { getAggregatedPerformance } from "features/profile/profileSlices";

const AggregatedPerformancePdf = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.aggregatedPerformance);

  useEffect(() => {
    dispatch(getAggregatedPerformance(currentProfile.code));
  }, [currentProfile.code]);

  const headers = ["", "3M", "6M", "1A", "3A", "5A", `Depuis ${data.since}`];
  const profileData = [
    data.profile_3m,
    data.profile_6m,
    data.profile_1a,
    data.profile_3a,
    data.profile_5a,
    data.perf_since,
  ].map((value) => {
    return value ? `${Number(value * 100).toFixed(2)}%` : "-";
  });
  const quantalysData = [
    data.quantalys_3m,
    data.quantalys_6m,
    data.quantalys_1a,
    data.quantalys_3a,
    data.quantalys_5a,
    data.perf_since_quantalys,
  ].map((value) => {
    return value ? `${Number(value * 100).toFixed(2)}%` : "-";
  });
  return (
    <div className="aggregated-performance-pdf">
      <div className="aggregated-performance-pdf__header">
        <div className="aggregated-performance-pdf__header__title">
          Performance cumulée
        </div>
      </div>
      <div className="aggregated-performance-pdf__content">
        <table>
          <thead>
            <tr>
              {headers.map((header, index) => {
                return <th key={index}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            <tr key={"profile-values"}>
              <td>{currentProfile.name}</td>
              {profileData.map((value, index) => {
                return <td key={index}>{value}</td>;
              })}
            </tr>
            {/* <tr key={"quantalys-values"}>
              <td>{"Catégorie Quantalys"}</td>
              {quantalysData.map((value, index) => {
                return <td key={index}>{value}</td>;
              })}
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AggregatedPerformancePdf;
