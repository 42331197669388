import './header-pdf.scss'
import { StringStore } from "StringStore";
import { useDispatch, useSelector } from "react-redux";

const HeaderPdf = () =>{
  const data = useSelector((state: any) => state.profile.performanceChartData);
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const lastUpdateDate = data.labels[data.labels.length - 1];
    return (
        <div className="header-pdf">
            <div className="header-pdf__title">
             {`Portefeuille conseillé : ${currentProfile.name}`}
             </div>
             <div className="header-pdf__last-update-box">
              <div className="header-pdf__last-update-box__one">
                {`${StringStore.profile.explanationButton} ${lastUpdateDate}`}
              </div>
             </div>
        </div>
    )
}

export default HeaderPdf;