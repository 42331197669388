// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aggregated-performance-pdf {
  height: fit-content;
  width: 100%;
}
.aggregated-performance-pdf__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5cm;
  padding-left: 0.5cm;
}
.aggregated-performance-pdf__header__title {
  font-size: 0.5cm;
  font-weight: 800;
  text-align: start;
}
.aggregated-performance-pdf__content {
  height: 100%;
  padding-left: 0.5cm;
}
.aggregated-performance-pdf__content .achat-vente-table {
  overflow-x: auto;
}
.aggregated-performance-pdf__content table {
  border-collapse: collapse;
  width: 40%;
}
.aggregated-performance-pdf__content th,
.aggregated-performance-pdf__content td {
  border: 0.0625rem solid #ddd;
  padding: 0.16cm;
  font-size: 0.35cm;
  height: 0.3cm;
  text-align: left;
  max-width: fit-content;
  text-wrap: nowrap;
}
.aggregated-performance-pdf__content th {
  background-color: #4babb9;
  color: white;
  font-size: 0.3cm;
}
.aggregated-performance-pdf__content th:first-child {
  border-top: transparent;
  border-left: transparent;
  background-color: transparent;
}
.aggregated-performance-pdf__content .bold {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/features/pdf/components/aggregated-performance-pdf/aggregated-performance-pdf.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,WAAA;AACD;AAAC;EACC,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,oBAAA;EACA,mBAAA;AAEF;AADE;EACI,gBAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AAAA;EACI,YAAA;EACA,mBAAA;AAEJ;AADI;EACI,gBAAA;AAGR;AAAM;EACE,yBAAA;EACA,UAAA;AAER;AACM;;EAEE,4BAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,iBAAA;AACR;AAEM;EACE,yBAAA;EACA,YAAA;EACA,gBAAA;AAAR;AAEQ;EACE,uBAAA;EACA,wBAAA;EACA,6BAAA;AAAV;AAIM;EACE,iBAAA;AAFR","sourcesContent":[".aggregated-performance-pdf{\n height: fit-content;\n width: 100%;\n &__header{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 0.5cm;\n  padding-left: 0.5cm;\n  &__title{\n      font-size: 0.5cm;\n      font-weight: 800;\n      text-align: start;\n  }\n}\n&__content{\n    height: 100%;\n    padding-left: 0.5cm;\n    .achat-vente-table {\n        overflow-x: auto;\n      }\n      \n      table {\n        border-collapse: collapse;\n        width: 40%;\n      }\n      \n      th,\n      td {\n        border: 0.0625rem solid #ddd;\n        padding: 0.16cm;\n        font-size: 0.35cm;\n        height: 0.3cm;\n        text-align: left;\n        max-width: fit-content;\n        text-wrap: nowrap;\n      }\n      \n      th {\n        background-color: #4babb9;\n        color: white;\n        font-size: 0.3cm;\n        \n        &:first-child {\n          border-top: transparent;\n          border-left: transparent;\n          background-color: transparent;\n        }\n      }\n      \n      .bold {\n        font-weight: bold;\n      }\n      \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
