// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallet-commentary {
  margin-top: 3rem;
  max-width: 100%;
}
.wallet-commentary__title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 1rem;
  text-align: start;
}
.wallet-commentary__content {
  width: 100%;
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/wallet-commentary/wallet-commentary.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;AACJ;AAAI;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AAER;AAAI;EACI,WAAA;EACA,iBAAA;AAER","sourcesContent":[".wallet-commentary{\n    margin-top: 3rem;\n    max-width: 100%;\n    &__title{\n        font-size: 1.25rem;\n        font-weight: 800;\n        margin-bottom: 1rem;\n        text-align: start;\n    }\n    &__content{\n        width: 100%;\n        text-align: start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
