import { useCallback, useEffect, useMemo } from "react";
import "./wallet-commentary.scss";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getPerformanceChartSeries, getWalletCommentary } from "features/profile/profileSlices";
import { StringStore } from "StringStore";
import { ClipLoader } from "react-spinners";
import { textFormatter } from "utils/fileFormatter";  

const WalletCommentary = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.walletCommentary);

  useEffect(() => {
    dispatch(getWalletCommentary(currentProfile.code));
  }, [currentProfile.code]);

  const formattedData = useMemo(() => {
    return textFormatter(data);
  }, [data]);
  return (
    <div className="wallet-commentary">
      <div className="wallet-commentary__title">Structure du portefeuille</div>
      <p
        className="wallet-commentary__content"
        dangerouslySetInnerHTML={{ __html: formattedData }}
      ></p>
    </div>
  );
};

export default WalletCommentary;
