// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallet-commentary-pdf {
  width: 100%;
  height: fit-content;
  position: relative;
  margin-top: 0.5cm;
}
.wallet-commentary-pdf__title {
  font-size: 0.5cm;
  font-weight: 800;
  margin-bottom: 0.25cm;
  text-align: start;
  padding-left: 0.5cm;
}
.wallet-commentary-pdf__content {
  width: 90%;
  align-items: center;
  padding: 0.5cm;
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/features/pdf/components/wallet-commentary-pdf/wallet-commentary-pdf.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AAAI;EACI,gBAAA;EACA,gBAAA;EACA,qBAAA;EACA,iBAAA;EACA,mBAAA;AAER;AAAI;EACI,UAAA;EACA,mBAAA;EACA,cAAA;EACA,iBAAA;AAER","sourcesContent":[".wallet-commentary-pdf{\n    width: 100%;\n    height: fit-content;\n    position: relative;\n    margin-top: 0.5cm;\n    &__title{\n        font-size: 0.5cm;\n        font-weight: 800;\n        margin-bottom: 0.25cm;\n        text-align: start;\n        padding-left: 0.5cm;\n    }\n    &__content{\n        width: 90%;\n        align-items: center;\n        padding: 0.5cm;\n        text-align: start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
