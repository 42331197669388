// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arbitration-table-pdf {
  height: fit-content;
  width: 100%;
  padding-left: 0.5cm;
}
.arbitration-table-pdf__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.arbitration-table-pdf__header__title {
  font-size: 0.5cm;
  font-weight: 800;
  text-align: start;
}
.arbitration-table-pdf__header__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EF936C;
  color: white;
  width: 16rem;
  height: 1.5rem;
  border-radius: 0.5rem;
  align-self: center;
  cursor: pointer;
}
.arbitration-table-pdf__content {
  height: 100%;
  width: 95%;
  font-size: 0.25cm;
}
.arbitration-table-pdf__content .achat-vente-table {
  overflow-x: auto;
}
.arbitration-table-pdf__content table {
  border-collapse: collapse;
  max-width: 30cm;
}
.arbitration-table-pdf__content th,
.arbitration-table-pdf__content td {
  border: 0.0625rem solid #ddd;
  padding: 0.1cm;
  height: 1cm;
  text-align: left;
  text-wrap: wrap;
  max-width: 6cm;
}
.arbitration-table-pdf__content th:last-child,
.arbitration-table-pdf__content td:last-child {
  max-width: 10cm;
}
.arbitration-table-pdf__content td {
  height: 100%;
}
.arbitration-table-pdf__content th {
  background-color: #4babb9;
  color: white;
}
.arbitration-table-pdf__content .bold {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/features/pdf/components/arbitration-table-pdf/arbitration-table-pdf.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,WAAA;EACA,mBAAA;AACD;AAAC;EACC,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAEF;AADE;EACI,gBAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AADE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;EACA,eAAA;AAGJ;AAAA;EACI,YAAA;EACA,UAAA;EACA,iBAAA;AAEJ;AADI;EACI,gBAAA;AAGR;AAAM;EACE,yBAAA;EAEA,eAAA;AACR;AAEM;;EAEE,4BAAA;EACA,cAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AAAR;AACQ;;EACE,eAAA;AAEV;AACM;EACE,YAAA;AACR;AACM;EACE,yBAAA;EACA,YAAA;AACR;AAEM;EACE,iBAAA;AAAR","sourcesContent":[".arbitration-table-pdf{\n height: fit-content;\n width: 100%;\n padding-left: 0.5cm;\n &__header{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n  &__title{\n      font-size: 0.5cm;\n      font-weight: 800;\n      text-align: start;\n  }\n  &__button{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #EF936C;\n    color: white;\n    width: 16rem;\n    height: 1.5rem;\n    border-radius: 0.5rem;\n    align-self: center;\n    cursor: pointer;\n  }\n}\n&__content{\n    height: 100%;\n    width: 95%;\n    font-size: 0.25cm;\n    .achat-vente-table {\n        overflow-x: auto;\n      }\n      \n      table {\n        border-collapse: collapse;\n      \n        max-width: 30cm;\n      }\n      \n      th,\n      td {\n        border: 0.0625rem solid #ddd;\n        padding: 0.1cm;\n        height: 1cm;\n        text-align: left;\n        text-wrap: wrap;\n        max-width: 6cm;\n        &:last-child {\n          max-width: 10cm;\n        }\n      }\n      td{\n        height:100%;\n      }\n      th {\n        background-color: #4babb9;\n        color: white;\n      }\n      \n      .bold {\n        font-weight: bold;\n      }\n      \n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
